import { DAEX_COLORS_SECONDARY_GRADIENT, KEYS, LABELS } from '@constants'
import {
  IAcCompanyDetailSeriesData,
  IAcCompanyDetailSubsetMarkline,
} from '@typings'

export interface IAcBarChartSubsetWomenCountMap {
  seriesData: IAcCompanyDetailSeriesData[]
  markLine: IAcCompanyDetailSubsetMarkline
  subset?: 'directors' | 'supervisory' | 'subtop'
  fullWidth?: boolean
}

export interface ISeries {
  name: string
  type: string
  data: number[]
  itemStyle: any
  label: any
  barWidth: any
  barGap: string
  markLine?: any
}

type IBarSeriesKey = 'ORGANISATION' | 'DIRECTORS' | 'SUPERVISORY' | 'SUBTOP'

const BAR_KEYS = [
  KEYS.ORGANISATION,
  KEYS.DIRECTORS,
  KEYS.SUPERVISORY,
  KEYS.SUBTOP,
]

export const AcBarChartSubsetWomenCountMap = ({
  seriesData = [],
  markLine,
  subset,
  fullWidth = false,
}: IAcBarChartSubsetWomenCountMap) => {
  const ChartConfig = {
    isSubset: !!subset,
    years: [] as string[],
    series: [] as ISeries[],
  }

  // Loop over years in series
  for (const [index, series] of seriesData.entries()) {
    const { year, status, ...bars } = series
    const appendSeriesData = !!index

    ChartConfig.years.push(year.toString())

    // Custom order of barKeys to match DAEX_COLORS_SECONDARY
    let visibleBarKeys: string[] = BAR_KEYS

    // If only a subset should be displayed, select only data of certain barKey
    if (subset) {
      if (visibleBarKeys.includes(subset as string))
        visibleBarKeys = [subset as string]
      else return ChartConfig
    }

    // Loop over bars inside one year (organisation, directors, etc.)
    for (const [barIndex, _barKey] of visibleBarKeys.entries()) {
      const barKey = _barKey as keyof typeof bars
      const bar = series[barKey]

      const pct = bar?.current?.percentage

      // Add percentage to data array if series is already initialized in first year
      if (appendSeriesData && pct) {
        ChartConfig.series[barIndex].data.push(
          typeof pct === 'string' ? parseFloat(pct) : pct
        )
      } else {
        let percentage = bar?.current.percentage

        if (pct === '0.0' || pct === 0.0) {
          percentage = 0
        }

        if (!pct && pct !== 0) {
          percentage = KEYS.NO_DATA
        }

        const markLineData = markLine?.target
          ? [
              {
                name: `${barKey} Streefcijfer voor ${markLine.year}`,
                yAxis: markLine.target,
              },
            ]
          : undefined

        ChartConfig.series.push({
          name: LABELS[barKey.toUpperCase() as IBarSeriesKey],
          type: 'bar',
          // @ts-expect-error: cast to string
          data: [percentage],
          barWidth: fullWidth ? '90%' : 32,
          barGap: '150%',
          markLine: {
            data: markLineData,
            symbol: 'none',
          },
          itemStyle: {
            borderRadius: [6, 6, 0, 0],
            color: subset
              ? DAEX_COLORS_SECONDARY_GRADIENT[
                  (BAR_KEYS as string[]).indexOf(subset as string)
                ]
              : undefined,
          },
          label: {
            position: 'top',
            show: true,
            formatter: ({ value }: any) => {
              const formatter = new Intl.NumberFormat('nl-NL', {
                style: 'percent',
                maximumFractionDigits: 1,
              })
              if (value || value === 0)
                return `${formatter.format(value / 100)}`
              return value
            },
            color: 'inherit',
            fontSize: 15,
            lineHeight: 20,
            align: 'center',
          },
        })
      }
    }
  }

  return ChartConfig
}
