import {
  AcCompanyDetailEmployees,
  AcCompanyDetailWomenChartComponent,
} from '@components'
import { LABELS } from '@constants'

export const AcDataExplorerCompanyOrganisationTab = () => {
  return (
    <>
      <AcCompanyDetailWomenChartComponent
        subset={undefined}
        title="Percentage vrouwen in de top en subtop"
        subTitle="Percentage vrouwen in het bestuur, de RvC, subtop en organisatie"
      />
      <AcCompanyDetailEmployees
        title="Aantal werknemers"
        subTitle="Totaal aantal werknemers in de organisatie"
        subset="organisation"
        badge={{
          label: `Einde boekjaar ${LABELS.CURRENT_YEAR}`,
          subset: 'organisation',
        }}
      />
    </>
  )
}
