import { DAEX_COLORS_SECONDARY_GRADIENT } from '@constants'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import {
  IAnalyticsAppointments,
  IAnalyticsCompanySubtopFlowItem,
  IAnalyticsLongitudinalYear,
} from '@typings'
import { useMemo } from 'react'
import { xAxisYearLabelFormatter } from '../ac-charts-shared-styles/ac-charts-shared-styles'

export interface IAcBarChartSubsetAppointmentsYear {
  year: string | number
  status: IAnalyticsLongitudinalYear['status']
  data?: {
    type:
      | IAnalyticsAppointments['type']
      | IAnalyticsCompanySubtopFlowItem['type']
    percentage?: string | number | null
    total: number | null
    value: number
  }
}

export interface IAcBarChartSubsetAppointments {
  data: IAcBarChartSubsetAppointmentsYear[]
  subset: 'directors' | 'supervisory' | 'subtop'
}

export const AcBarChartSubsetAppointments = ({
  data,
  subset,
}: IAcBarChartSubsetAppointments) => {
  const barColor = useMemo(() => {
    switch (subset) {
      case 'directors':
        return DAEX_COLORS_SECONDARY_GRADIENT[1]

      case 'supervisory':
        return DAEX_COLORS_SECONDARY_GRADIENT[2]

      case 'subtop':
        return DAEX_COLORS_SECONDARY_GRADIENT[3]
    }
  }, [subset])

  const chartData = {
    animationDelayUpdate: function (idx: number) {
      return idx * 5
    },
    series: [
      {
        type: 'bar',
        data: data.map(item => item?.data?.percentage),
        itemStyle: {
          borderRadius: [6, 6, 0, 0],
        },
      },
    ],
    label: {
      position: 'top',
      show: true,
      formatter: ({ value }: any) => {
        const formatter = new Intl.NumberFormat('nl-NL', {
          style: 'percent',
          maximumFractionDigits: 1,
        })
        if (value) return `${formatter.format(value / 100)}`
        return value
      },
    },
    textStyle: {
      fontFamily: 'Dax',
    },
    xAxis: [
      {
        type: 'category',
        data: data.map(item => item.year),
        position: 'bottom',
        offset: 16,
        axisPointer: {
          type: 'shadow',
        },
        axisLine: { show: false },
        axisTick: { show: false },
        axisLabel: xAxisYearLabelFormatter(data),
      },
    ],
    yAxis: [
      {
        type: 'value',
        min: 0,
        max: 100,
        interval: 25,
        axisLabel: {
          formatter: '{value}%',
        },
        minorTick: {
          splitNumber: 2,
        },
        minorSplitLine: {
          show: true,
        },
      },
    ],
    color: barColor,
    grid: {
      bottom: 100,
      left: 40,
      right: 0,
    },
  }

  return (
    <ReactEChartsCore
      style={{ height: '420px' }}
      echarts={echarts}
      option={chartData}
      key={'appointments'}
    />
  )
}
