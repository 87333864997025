import { AcCardWithTitle } from '@components'
import { ICONS } from '@constants'
import styles from './ac-company-details-subtop-flow-progression.module.scss'
import { useStore } from '@hooks/ac-use-store'
import { useMemo } from 'react'
import { AcBarChartSubsetAppointments } from '@charts'

export const AcCompanyDetailsSubtopFlowProgression = () => {
  const { analytics } = useStore()

  const influxData = useMemo(() => {
    if (!analytics.sortedLongitudinalYear?.length) return undefined
    return analytics.sortedLongitudinalYear.map(
      ({ year, appointments, status }) => ({
        year,
        status,
        data: appointments?.subtop.find(item => item.type === 'influx'),
      })
    )
  }, [analytics.sortedLongitudinalYear])

  const outFlowData = useMemo(() => {
    if (!analytics.sortedLongitudinalYear?.length) return null
    return analytics.sortedLongitudinalYear.map(
      ({ year, appointments, status }) => ({
        year,
        status,
        data: appointments?.subtop.find(item => item.type === 'outflow'),
      })
    )
  }, [analytics.sortedLongitudinalYear])

  return (
    <div className={styles['ac-company-details-subtop-flow-progression']}>
      <AcCardWithTitle
        title="Instroom in de subtop"
        subTitle="Percentage vrouwen bij aanstellingen"
        icon={ICONS.INFLUX}
        iconSize={32}
        iconColor="var(--ac-color-secondary-lightblue2)">
        {influxData?.length && (
          <AcBarChartSubsetAppointments
            subset="subtop"
            data={influxData}
          />
        )}
      </AcCardWithTitle>
      <AcCardWithTitle
        title="Uitstroom uit de subtop"
        subTitle="Percentage vrouwen bij uitstroom"
        icon={ICONS.OUTFLOW}
        iconSize={32}
        iconColor="var(--ac-color-secondary-lightblue2)">
        {outFlowData?.length && (
          <AcBarChartSubsetAppointments
            subset="subtop"
            data={outFlowData}
          />
        )}
      </AcCardWithTitle>
    </div>
  )
}
