import {
  AcButton,
  AcCardGrid,
  AcHelmet,
  AcTitle,
  AcTypography,
} from '@components'
import { DAEX_ABOUT, DAEX_META } from '@constants'

export const AcDataExplorerAboutView = () => {
  const data_url =
    'https://www.ser.nl/-/media/ser/downloads/thema/diversiteitsportaal/2025/ser-scorecard-2025.pdf'

  const handleDownload = () => window.open(data_url, '_blank')

  return (
    <AcCardGrid>
      <AcHelmet
        title={DAEX_META.ABOUT.TITLE}
        description={DAEX_META.ABOUT.DESCRIPTION}
      />
      <div>
        <AcTitle level={4}>{DAEX_ABOUT.TITLE.SUB}</AcTitle>
        <AcTitle>{DAEX_ABOUT.TITLE.MAIN}</AcTitle>
      </div>
      <div>
        <AcTypography
          size="lg"
          color="primary">
          {DAEX_ABOUT.INTRO}
        </AcTypography>
      </div>
      <div>
        <div>
          <AcTitle level={3}>Aanleiding</AcTitle>
          <AcTypography>
            Sinds <strong>1 januari 2022</strong> is de Wet evenwichtiger verhouding tussen het aantal mannen en vrouwen in het bestuur en de raad van commissarissen van kracht (beter bekend als de Wet ingroeiquotum en streefcijfers). Deze wet komt voort uit het SER advies Diversiteit in de top. Tijd voor versnelling (2019).
          </AcTypography>
          <AcTypography>
            De wet verplicht grote vennootschappen en beursgenoteerde bedrijven om:
            <ul>
              <li><strong>Passende en ambitieuze streefcijfers</strong> op te stellen voor de man-vrouwverhouding in hun bestuur, raad van commissarissen (rvc) en subtop. </li>
              <li>Een <strong>plan van aanpak</strong> te formuleren om deze streefcijfers te behalen.</li>
              <li><strong>Jaarlijks te rapporteren</strong> over hun voortgang aan de SER.</li>
            </ul>
            De <strong>SER dataverkenner</strong> maakt de rapportages openbaar en toegankelijk.
          </AcTypography>
          <AcTypography>
            <i>Beursgenoteerde bedrijven: ingroeiquotum rvc</i><br/>
            Voor beursgenoteerde bedrijven geldt naast de streefcijferregeling een <strong>ingroeiquotum voor de rvc</strong>. Dit houdt in dat ten minste <strong>één
            derde van de zetels door vrouwen en ten minste één derde door mannen</strong> moet worden bezet. Benoemingen die deze balans niet verbeteren, worden als nietig beschouwd en de vacature blijft dan open.
          </AcTypography>
          <AcTypography>
            Met deze wet zetten circa <strong>100 beursgenoteerde bedrijven</strong> en <strong>5.500 grote
            vennootschappen</strong> een stap richting een evenwichtigere vertegenwoordiging van vrouwen en mannen in de top en subtop. De SER ondersteunt bedrijven hierbij.
          </AcTypography>
        </div>
        <div>
          <AcTitle level={3}>SER Monitor Genderbalans in het bedrijfsleven</AcTitle>
          <AcTypography>
            De SER publiceert jaarlijks de <strong>SER Monitor Genderbalans</strong>, die inzicht geeft in de stand van genderdiversiteit
            in de top en subtop van grote Nederlandse bedrijven en de effecten van de wet. Deze Monitor bestaat uit drie
            onderdelen: SER Dataverkenner, SER Scorecard en SER Rapportage.
          </AcTypography>
          <AcTypography>
            <strong>SER Dataverkenner:</strong> Geeft toegang tot de rapportages van bedrijven. Hier zie je gegevens per bedrijf, sector,
            bedrijfsgrootte en heel Nederland.
          </AcTypography>
          <AcTypography>
            <strong>SER Scorecard:</strong> Een samenvattend overzicht van de genderbalans in de top en subtop van bedrijven.
            <ul>
              <li>De <strong>SER Scorecard 2025</strong> is gebaseerd op de rapportages over boekjaar <strong>2023</strong> die
                bedrijven hebben ingediend
                via het SER Diversiteitsportaal.
              </li>
              <li>De <strong>SER Scorecard 2024</strong> gaat over boekjaar <strong>2022</strong>.</li>
            </ul>
            <strong>SER Rapportage:</strong> Een uitgebreide rapportage. De <strong>SER Rapportage 2025</strong> komt in de zomer van 2025 beschikbaar via
            www.diversiteitsportaal.nl.
          </AcTypography>
          <div style={{paddingBottom: 'var(--ac-pixel-size-16)'}}>
            <AcButton
                label="SER Scorecard 2025"
                onClick={handleDownload}
            />
          </div>
        </div>
        <div>
          <AcTitle level={3}>Waarom rapporteren aan de SER?</AcTitle>
          <AcTypography>
            De SER dataverkenner stelt bedrijven in staat om zichzelf te vergelijken met andere organisaties en sectoren. Door te rapporteren:
            <ul>
              <li><strong>Dragen bedrijven bij aan inzicht</strong> in genderdiversiteit binnen de Nederlandse bedrijfstop. </li>
              <li><strong>Kunnen bedrijven hun diversiteitsbeleid versterken</strong> met behulp van de benchmarkgegevens in de dataverkenner.</li>
              <li><strong>Wordt transparantie bevordert</strong>, wat essentieel is voor een evenwichtigere man-vrouwverhouding in de top en subtop.</li>
            </ul>
            De SER verzamelt gegevens in een gestandaardiseerd format en maakt deze openbaar en toegankelijk via de SER dataverkenner. Hierdoor kunnen bedrijven van elkaar leren en krijgen belanghebbenden een actueel beeld van de genderbalans binnen bedrijven.
          </AcTypography>
        </div>
        <div>
          <AcTitle level={3}>Toelichting op de data in de SER dataverkenner</AcTitle>
          <AcTypography>
            De SER dataverkenner toont de streefcijfers en ontwikkelingen van de man-vrouwverhouding op drie verschillende niveaus:
            <ol>
              <li><strong>Per bedrijf</strong></li>
              <li><strong>Per sector en bedrijfsgrootte</strong></li>
              <li><strong>Voor Nederland als geheel</strong></li>
            </ol>
          </AcTypography>
          <AcTypography>
            <strong>1. Ontwikkeling genderbalans per bedrijf</strong>
          </AcTypography>
          <AcTypography>
            <ul>
              <li>De ingediende rapportages worden zonder tussenkomst van de SER gepubliceerd in de Dataverkenner.</li>
              <li>Rapportages over <strong>boekjaar 2023</strong> zijn inzichtelijk vanaf <strong>10 maart 2025</strong>.</li>
              <li>Het aantal beschikbare rapportages van bedrijven kan variëren, onder andere omdat bedrijven met een gebroken boekjaar later mogen rapporteren.</li>
              <li>Naast verplichte rapportages zijn er ook vrijwillige rapportages van stichtingen, coöperaties en kleinere vennootschappen. </li>
            </ul>
          </AcTypography>
          <AcTypography>
            <strong>
              2. Ontwikkeling genderbalans in Nederland, per sector en
              bedrijfsgrootte
            </strong>
          </AcTypography>
          <AcTypography>
            De analyses naar sector, bedrijfsgrootte en voor heel Nederland zijn gebaseerd op een selectie van bedrijven de SER dataverkenner, de zogenoemde <strong>'analysegroep'</strong>.
            Deze groep bestaat uit de  bedrijven die:
            <ol>
              <li>Verplicht moesten rapporteren in het betreffende boekjaar.</li>
              <li>Hun rapportage vóór de deadline hebben ingediend.</li>
              <li>Een boekjaar hadden dat eindigde op 31 december, voor vergelijkbaarheid.</li>
            </ol>
          </AcTypography>
          <AcTypography>
            Publieke organisaties, vrijwillig rapporterende bedrijven en rapportages met onvolledige of ongeldige data zijn niet meegenomen in de analysegroep.
          </AcTypography>
          <AcTypography>
            Over <strong>boekjaar 2023</strong> zijn de rapportages van <strong>3.079 vennootschappen</strong> geanalyseerd.
          </AcTypography>
          <AcTypography>
            De SER dataverkenner bevat dus <strong>meer rapportages</strong> dan de analyses, omdat niet alle ingediende rapportages in de analysegroep vallen.
          </AcTypography>
          <AcTypography>
            <strong>Groepen die gezamenlijk rapporteren</strong>
            <br />
            Bedrijven die tot een groep behoren kunnen ervoor kiezen <strong>gezamenlijk te rapporteren</strong> via het groepshoofd. In dat geval worden de volgende gegevens op groepsniveau gerapporteerd:
          </AcTypography>
          <AcTypography element="span">
            <ul>
              <li>
                <strong>Streefcijfers:</strong> Eén streefcijfer voor het bestuur en één streefcijfer voor de rvc, geldend voor alle besturen en alle rvc’s in de groep.
              </li>
              <li>
                <strong>Bedrijfsgrootte (aantal werknemers):</strong> Het totaal aantal werknemers van de groep, in personen (head count).
              </li>
              <li>
                <strong>Definitie van de subtop:</strong> Gedefinieerd op het niveau van de groep. Medewerkers die gestationeerd zijn in het buitenland mogen worden meegeteld als dat past in definitie van de subtop.
              </li>
              <li>
                <strong>Man-vrouwverhouding bestuur en RvC:</strong> Per groepsmaatschappij apart gerapporteerd.
              </li>
              <li>
                <strong>Man-vrouwverhouding subtop:</strong> Voor de groep als geheel gerapporteerd.
              </li>
              <li>
                <strong>Plan van aanpak:</strong> Eén plan voor alle betrokken groepsmaatschappijen.
              </li>
            </ul>
          </AcTypography>
          <AcTypography>
            De SER Dataverkenner toont de informatie zowel voor het hoofd van de groep als per groepsmaatschappij. Zo is het plan van aanpak zowel bij het groepshoofd als bij de groepsmaatschappij te zien.
          </AcTypography>
          <AcTypography>
            <i>Effect op de analyses</i><br/>
            Voor de analyses naar sector, bedrijfsgrootte en voor heel Nederland wordt de groepsinformatie over het aantal werknemers, de subtop en het plan van aanpak slechts één keer meegenomen. Bijvoorbeeld: als een groep met vijf groepsmaatschappijen gezamenlijk rapporteert, telt het percentage vrouwen in de subtop slechts één keer mee in het nationale gemiddelde.
          </AcTypography>
          <AcTypography>
            Meer informatie over de data, analysemethode en het veldwerk is te vinden in de SER Monitor Genderbalans in het bedrijfsleven 2025, die in de zomer 2025 verschijnt. Deze zal beschikbaar zijn via www.diversiteitsportaal.nl.
          </AcTypography>
        </div>
        <div>
          <AcTitle level={3}>Toelichting begrippen</AcTitle>
          <AcTypography>
            <strong>Genderdiversiteit:</strong> Bedrijven kunnen rekening houden met personen die zich niet thuis voelen binnen het klassieke man-vrouwhokje. Gelet op de privacy, kan het onwenselijk zijn als dit soort gegevens te herleiden zijn tot bepaalde personen. Daarom kunnen bedrijven personen uit deze categorie meetellen bij het ondervertegenwoordigde geslacht.
          </AcTypography>
          <AcTypography>
            <strong>Grote vennootschap:</strong> Een vennootschap is volgens het jaarrekeningrecht ‘groot’ als de jaarrekening op twee opeenvolgende balansdata, zonder onderbreking nadien op twee opeenvolgende balansdata, voldoet aan ten minste twee van de volgende criteria:
          </AcTypography>
          <AcTypography element="span">
            <ul>
              <li>de waarde van de activa bedraagt meer dan € 25 miljoen; </li>
              <li>de netto-omzet is meer dan € 50 miljoen;</li>
              <li>het gemiddeld aantal werknemers is 250 of meer.</li>
            </ul>
          </AcTypography>
          <AcTypography>
            De groottecriteria zijn in 2024 <strong>gewijzigd</strong> en zijn van toepassing op boekjaren die zijn aangevangen op of na 1 januari 2024, en kunnen worden toegepast op de boekjaren die zijn aangevangen op of na 1 januari 2023.
          </AcTypography>
          <AcTypography>
            <strong>Streefcijfer:</strong> De wet verplicht grote vennootschappen zelf passende en ambitieuze streefcijfers te formuleren voor het bestuur, de rvc en de subtop. Een streefcijfer is een minimumpercentage dat geldt voor vrouwen én mannen. Een streefcijfer van 40% m/v voor het bestuur betekent dus dat het bestuur voor ten minste 40% uit vrouwen en voor ten minste 40% uit mannen moet bestaan.
            <ul>
              <li>'Passend’ wil zeggen dat het streefcijfer afhangt van de omvang van het bestuur, de rvc of de subtop én van de huidige man-vrouwverhouding. </li>
              <li>'Ambitieus' betekent dat het streefcijfer is gericht op een evenwichtiger samenstelling dan in de bestaande situatie. Het streefcijfer mag dus niet 0% zijn. </li>
            </ul>
          </AcTypography>
          <AcTypography>
            <strong>Subtop:</strong> Grote vennootschappen stellen streefcijfers op voor werknemers in leidinggevende posities, de zogenoemde subtop. Zij mogen zelf bepalen wat zij onder de subtop verstaan en voor welke subtop zij streefcijfers vaststellen. Groepsmaatschappijen die gezamenlijk rapporteren, definiëren de subtop op het niveau van de groep.
          </AcTypography>
          <AcTypography>
            In eerder onderzoek van de Bedrijvenmonitor gold voor de subtop een vaste definitie, namelijk de eerste twee lagen onder het bestuur. De gegevens over de subtop na 2022 zijn daarom niet een-op-een vergelijkbaar met die uit eerdere jaren.
          </AcTypography>
          <AcTypography>
            <strong>Formeel plan van aanpak:</strong> Onder een formeel plan van aanpak wordt een op zichzelf staand beleid verstaan of beleid dat is opgenomen in een ander beleidsplan. Het beleid moet zijn beschreven en goedgekeurd zijn door het bestuur.
          </AcTypography>
          <AcTypography>
            <strong>Aftreden:</strong> Bestuurders of commissarissen die het bestuur of de rvc gedurende het boekjaar hebben verlaten, bijvoorbeeld omdat de zittingstermijn is verstreken of omdat zij – al dan niet vrijwillig – tussentijds zijn opgestapt.
          </AcTypography>
          <AcTypography>
            <strong>Instroom en uitstroom subtop</strong>:{' '}
          </AcTypography>
          <AcTypography element="span">
            <ul>
              <li>
                <strong>Instroom:</strong> Het aantal werknemers (in personen) dat gedurende het afgelopen boekjaar is aangesteld in een functie in de subtop. Het gaat zowel om personen van buiten de organisatie als om werknemers uit de eigen organisatie die werden aangesteld in een functie in de subtop en die voorheen geen functie in de subtop hadden.
              </li>
              <li>
                <strong>Uitstroom:</strong> het aantal werknemers (in personen) dat gedurende het afgelopen jaar de subtop heeft verlaten. Het gaat zowel om werknemers die binnen de organisatie doorstroomden naar een functie die niet tot de subtop wordt gerekend als om werknemers die de organisatie hebben verlaten.
              </li>
            </ul>
          </AcTypography>
        </div>
      </div>
    </AcCardGrid>
  )
}
