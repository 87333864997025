import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart, GaugeChart, LineChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent,
  MarkLineComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { useMemo } from 'react'

import { BREAKPOINTS, DAEX_COLORS_SECONDARY } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import {
  IAcCompanyDetailSeriesData,
  IAcCompanyDetailOverviewMarkline,
} from '@typings'
import { EChartsOption, TooltipComponentFormatterCallbackParams } from 'echarts'
import { AcLineChartCompanyDetailOverviewMap, ISeries } from '@charts'
import clsx from 'clsx'
import { renderTooltip } from './renderTooltip'
import { xAxisYearLabelFormatter } from '../ac-charts-shared-styles/ac-charts-shared-styles'

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GaugeChart,
  BarChart,
  LineChart,
  CanvasRenderer,
  MarkLineComponent,
])

interface IAcLineChartCompanyDetailOverview {
  seriesData: IAcCompanyDetailSeriesData[]
  markLine: IAcCompanyDetailOverviewMarkline
  className?: string
}

export const AcLineChartCompanyDetailOverview = ({
  seriesData,
  markLine,
  className,
}: IAcLineChartCompanyDetailOverview) => {
  const isMdAndUp = AcUseBreakpoint(BREAKPOINTS.MD)
  const getChartClasses = useMemo(
    () => clsx(className && className),
    [className]
  )

  const renderChart = useMemo(() => {
    const { years, series } = AcLineChartCompanyDetailOverviewMap({
      seriesData,
      markLine,
      showSymbol: isMdAndUp,
    })

    const options: EChartsOption = {
      series: [
        ...series,
        {
          name: 'Streefcijfers',
          type: 'line',
          symbol: 'none',
          itemStyle: {
            color: 'transparent',
          },
          lineStyle: {
            color: '#241d6d',
            type: 'dotted',
          },
        },
      ],
      legend: {
        left: 0,
        right: undefined,
        itemGap: isMdAndUp ? 40 : 20,

        data: [
          ...series.map((item: ISeries) => ({
            name: item.name,
            icon: 'circle',
          })),
          {
            name: 'Streefcijfers',
          },
        ],
        textStyle: {
          fontSize: 14,
        },
      },
      animationDelayUpdate: function (idx: number) {
        return idx * 5
      },
      textStyle: {
        fontFamily: 'Dax',
      },
      color: DAEX_COLORS_SECONDARY,
      tooltip: {
        trigger: 'item',
        confine: true,
        formatter: (item: TooltipComponentFormatterCallbackParams) =>
          renderTooltip(item, markLine),
      },
      grid: {
        bottom: 100,
        left: 40,
        right: 0,
      },
      xAxis: [
        {
          type: 'category',
          data: years,
          position: 'bottom',
          offset: 24,
          axisPointer: {
            type: 'shadow',
          },
          axisLine: { show: false },
          axisTick: { show: false },
          axisLabel: xAxisYearLabelFormatter(seriesData),
        },
      ],
      yAxis: [
        {
          type: 'value',
          min: 0,
          max: 100,
          interval: 10,
          axisLabel: {
            formatter: '{value}%',
          },
        },
      ],
    }

    return (
      <ReactEChartsCore
        className={getChartClasses}
        style={{ height: '420px', width: '100%' }}
        echarts={echarts}
        option={options}
      />
    )
  }, [seriesData, isMdAndUp])

  return renderChart
}
