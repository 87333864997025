import { LABELS, KEYS } from '@constants'
import { AcPercentageString } from '@helpers/ac-percentage-string.helpers'
import { IAcCompanyDetailOverviewMarkline } from '@typings'

export const renderTooltip = (
  item: any,
  markLine: IAcCompanyDetailOverviewMarkline
) => {
  const year = item.name
  const pct = AcPercentageString(item?.value)
  const type =
    item.seriesName === 'RvC' ? 'RvC' : item.seriesName?.toLowerCase()
  const currentData = `<span style="color: #241d6d;">${year} - ${type}<br/> <strong>${pct}% vrouwen</strong></span>`
  if (item.seriesName === 'Organisatie') return currentData

  let subset
  switch (item.seriesName) {
    case LABELS.DIRECTORS:
      subset = KEYS.DIRECTORS
      break

    case LABELS.SUBTOP:
      subset = KEYS.SUBTOP
      break

    case LABELS.SUPERVISORY:
      subset = KEYS.SUPERVISORY
      break
  }

  const subsetMarkline = {
    year: markLine[subset as keyof typeof markLine].year,
    percentage: markLine[subset as keyof typeof markLine].target,
  }

  const targets = !subsetMarkline?.percentage
    ? `(nog) geen streefcijfer  <br/> voor ${type}`
    : `${subsetMarkline?.year} - streefcijfer<br/> <strong>${AcPercentageString(
        subsetMarkline.percentage
      )}% vrouwen</strong>`

  if (item.componentType === 'markLine') return targets
  return (
    currentData +
    '<hr style="height: 1px; border: none; background-color: #DADADA;"/>' +
    targets
  )
}
