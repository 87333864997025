import {
  DAEX_COLORS_SECONDARY_GRADIENT,
  DAEX_DEPARTMENTS,
  KEYS,
} from '@constants'
import { IAnalyticsIndexFemales } from '@typings'

export interface IAcLineChartFemalesMap {
  seriesData: IAnalyticsIndexFemales
  showSymbol?: boolean
}

const BAR_KEYS = [
  KEYS.ORGANISATION,
  KEYS.DIRECTORS,
  KEYS.SUPERVISORY,
  KEYS.SUBTOP,
]

export const AcStackedPieChartFemalesMap = ({ seriesData }: any) => {
  const AcStackedPieChartFemalesConfig = {
    series: [] as any[],
  }

  // Custom order of barKeys to match DAEX_COLORS_SECONDARY_GRADIENT
  for (const [barIndex, barKey] of BAR_KEYS.entries()) {
    const circle = seriesData?.find(({ type }: any) => type === barKey)

    if (!circle) return AcStackedPieChartFemalesConfig

    AcStackedPieChartFemalesConfig.series.push({
      name: DAEX_DEPARTMENTS.WITH_LEADING_ARTICLE[barKey],
      type: 'pie',
      radius: [80 - 15 * barIndex + '%', 90 - 15 * barIndex + '%'],
      center: ['50%', '50%'],
      startAngle: 180,
      colorBy: 'series',
      color: DAEX_COLORS_SECONDARY_GRADIENT[barIndex],
      label: {
        show: false,
      },
      data: [
        circle.percentage,
        {
          value: 100 + circle.percentage,
          itemStyle: {
            color: 'none',
          },
        },
      ],
    })

    AcStackedPieChartFemalesConfig.series.push({
      name: DAEX_DEPARTMENTS.WITH_LEADING_ARTICLE[barKey],
      animationType: 'scale',
      type: 'pie',
      tooltip: {
        show: false,
      },
      radius: [
        77.5 - 15 * barIndex ? barIndex : -2 + '%',
        92.5 - 15 * barIndex + '%',
      ],
      center: ['50%', '50%'],
      startAngle: 180,
      colorBy: 'series',
      color: {
        type: 'linear',
        x: 0,
        y: 1,
        x2: 0,
        y2: 0,
        colorStops: [
          {
            offset: 0,
            color: '#f9d8f4' + (barIndex * 5).toString(16).padStart(2, '0'),
          },
          {
            offset: 0.7,
            color: '#FFFFFF' + (barIndex * 40).toString(16).padStart(2, '0'),
          },
        ],
      },
      z: 1,
      label: {
        show: false,
      },
      data: [
        100,
        {
          value: 100,
          itemStyle: {
            color: 'none',
          },
        },
      ],
    })
  }

  return AcStackedPieChartFemalesConfig
}
