export interface IAcHorizontalBarChartTargetMap {
  seriesData: any
}

export const AcHorizontalBarChartTargetMap = ({
  seriesData,
}: IAcHorizontalBarChartTargetMap) => {
  const ChartConfig = {
    series: [] as any[],
  }

  let index = 0
  const values = []

  for (const key of Object.keys(seriesData)) {
    const value = seriesData[key]
    if (value !== '0.00') {
      values.push(value)
    }
  }

  for (const key of Object.keys(seriesData)) {
    const value = seriesData[key]

    if (value !== '0.00') {
      index++
    }

    let borderRadius = [0, 0, 0, 0]
    if (index === 1) {
      borderRadius = [25, 0, 0, 25]
    }

    if (index === values.length) {
      borderRadius = [0, 25, 25, 0]
    }

    ChartConfig.series.push({
      name: key,
      type: 'bar',
      stack: 'total',
      data: [value],

      barWidth: '100%',
      barMinWidth: '64px',
      itemStyle: {
        normal: {
          barBorderRadius: borderRadius,
        },
      },
    })
  }

  return ChartConfig
}
