import clsx from 'clsx'
import { CSSProperties, useMemo } from 'react'

import {
  DAEX_COLORS_ACCENT,
  DAEX_TARGET_GROUPS,
  DYNAMIC_LABELS,
  KEYS,
  LABELS,
  TOOLTIPS,
  ICONS,
} from '@constants'
import {
  AcCardWithTitle,
  AcTitle,
  AcTypography,
  AcTooltip,
  AcIcon,
} from '@components'
import { AcHorizontalBarChartTarget } from '@charts'
import { IAnalyticsCompanyDepartments } from '@typings'

import styles from './ac-card-bar-target.module.scss'

interface IAcCardBarTarget {
  title: string
  subtitle: string
  seriesData: any[]
  visible?: boolean
}

const getColorvar = (department: string) => {
  switch (department.toLowerCase()) {
    case KEYS.DIRECTORS:
      return '--ac-color-secondary-orange'
    case KEYS.SUBTOP:
      return '--ac-color-secondary-lightblue2'
    case KEYS.SUPERVISORY:
      return '--ac-color-secondary-green'
    case KEYS.ORGANISATION:
      return '--ac-color-secondary-fuchia'
    default: {
      return '--ac-color-secondary-green'
    }
  }
}

export const AcCardBarTarget = ({
  title,
  subtitle,
  seriesData,
  visible = true,
}: IAcCardBarTarget) => {
  const renderChartRow = useMemo(() => {
    const charts = []
    for (const chart of seriesData) {
      const DEPARTMENT_KEY: IAnalyticsCompanyDepartments =
        chart.department?.toUpperCase()

      const department = LABELS[DEPARTMENT_KEY]
      const companies = chart.total?.toLocaleString()

      const percentageString =
        !chart.companies && !chart.average_target
          ? '-'
          : `${chart.average_target?.toFixed(1)?.replace('.', ',')}%`

      const hint = (TOOLTIPS.DAEX_INDEX.TARGET as any)[DEPARTMENT_KEY]

      const color = `var(${getColorvar(DEPARTMENT_KEY)})`

      charts.push(
        <div
          className={styles['ac-card-bar-target-chart-row']}
          key={chart.department}>
          <div>
            <AcTitle level={3}>
              <a style={{ color, fontSize: 'inherit' }}>{department}</a>
            </AcTitle>
            <AcTypography size="sm">
              {DYNAMIC_LABELS.N_COMPANIES(companies)}
            </AcTypography>
          </div>
          <div>
            <div className={styles['ac-card-bar-target-with-icon']}>
              <AcTitle level={2}>{percentageString}</AcTitle>
              <AcTooltip label={LABELS.TARGET_EXPLANATION}>
                <AcIcon
                  className={styles['ac-data-item-inner-info']}
                  icon={ICONS.INFO}
                  size={14}
                />
              </AcTooltip>
            </div>
            <AcTypography size="sm">{LABELS.AVERAGE_TARGET}</AcTypography>
          </div>
          <div className={styles['ac-card-bar-target-chart']}>
            <AcHorizontalBarChartTarget
              seriesData={chart.on_target}
              department={chart.department.toUpperCase()}
            />
            <AcTypography
              size="sm"
              className={styles['ac-card-bar-target-chart-hint']}>
              {hint}
            </AcTypography>
          </div>
        </div>
      )
    }
    return charts
  }, [seriesData])

  const renderLegendItems = useMemo(() => {
    const items = DAEX_TARGET_GROUPS.map((group, index) => (
      <span
        key={index}
        style={{ '--fill': DAEX_COLORS_ACCENT[index] } as CSSProperties}>
        {group}
      </span>
    ))
    return (
      <div className={styles['ac-card-bar-target-legend-items']}>{items}</div>
    )
  }, [])

  return (
    <div
      className={clsx(
        styles['ac-card-bar-target'],
        visible && styles['ac-card-bar-target--visible']
      )}>
      <AcCardWithTitle
        title={title}
        subTitle={subtitle}>
        <div className={styles['ac-card-bar-target-legend']}>
          <AcTypography
            weight="semibold"
            size="xs">
            Hoogte van het streefcijfer dat bedrijven hebben opgesteld (in
            categorieën)
          </AcTypography>
          {renderLegendItems}
        </div>
        <div className={styles['ac-card-bar-target-charts']}>
          {renderChartRow}
        </div>
      </AcCardWithTitle>
    </div>
  )
}
