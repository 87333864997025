import { BREAKPOINTS, ICONS, KEYS, LABELS } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import { useStore } from '@hooks/ac-use-store'
import { IAnalyticsAppointmentsSubset } from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { AcDataItem } from '../ac-data-item/ac-data-item'
import { AcCardWithTitle } from '../index.features.components'

interface IAppointmentItem {
  type: 'appointment' | 'reappointment' | 'resign'
  percentage: Nullable<number>
  value?: Nullable<number>
  total: Nullable<number>
}

interface IAcCompanyDetailAppointmentProps {
  title: string
  subTitle?: string
  subset: IAnalyticsAppointmentsSubset
}

export const AcCompanyDetailAppointment = observer(
  ({
    title,
    subTitle,
    subset = KEYS.DIRECTORS,
  }: IAcCompanyDetailAppointmentProps) => {
    const { analytics } = useStore()
    const isDesktop = AcUseBreakpoint(BREAKPOINTS.SM)

    const currentData = useMemo(
      () => analytics.current_year_longitudinal?.appointments?.[subset],
      [analytics.current_year_longitudinal]
    )

    const getIcon = (type: IAppointmentItem['type']) => {
      switch (type) {
        case 'appointment':
          return ICONS.WORKSPACE_PREMIUM
        case 'reappointment':
          return ICONS.RESTART
        case 'resign':
          return ICONS.LOGOUT
      }
    }

    const getLabel = (item: IAppointmentItem) => {
      return `${item.value} vrouwen van totaal ${item.total} ${subset == KEYS.DIRECTORS ? 'bestuurders' : 'commissarissen'}`
    }

    const getSubtitle = (item: IAppointmentItem) => {
      switch (item.type) {
        case 'appointment':
          return 'vrouwen benoemd'
        case 'reappointment':
          return 'vrouwen herbenoemd'
        case 'resign':
          return 'vrouwen afgetreden'
      }
    }

    const currentColor = useMemo(() => {
      switch (subset) {
        case 'supervisory':
          return '--ac-color-secondary-green'
        default:
          return '--ac-color-secondary-orange'
      }
    }, [subset])

    return (
      <AcCardWithTitle
        title={title}
        subTitle={subTitle}
        badge={{
          label: `Boekjaar ${LABELS.CURRENT_YEAR}`,
          subset,
        }}>
        {currentData?.map(singleItem => (
          <AcDataItem
            key={singleItem.type}
            title={`${singleItem.percentage}%`}
            subtitle={getSubtitle(singleItem)}
            label={getLabel(singleItem)}
            direction={isDesktop ? 'horizontal' : 'vertical'}
            iconColor={currentColor}
            icon={getIcon(singleItem.type)}
          />
        ))}
      </AcCardWithTitle>
    )
  }
)
