import ReactEChartsCore from 'echarts-for-react/lib/core'
import { GaugeChart, PieChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  // AxisPointerComponent,
  // BrushComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { useMemo } from 'react'

import { CanvasRenderer } from 'echarts/renderers'
import { AcPieChartFemalesMap } from './ac-pie-chart-females-map'
import {
  COLORS,
  DAEX_COLORS_SECONDARY,
  DAEX_COLORS_SECONDARY_GRADIENT,
  KEYS,
} from '@constants'
import { AcUseViewportWidth } from '@hooks/ac-use-viewport-width'
import clsx from 'clsx'

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GaugeChart,
  PieChart,
  CanvasRenderer,
])

interface IAcPieChartFemales {
  seriesData: {
    value: Nullable<number>
    percentage: Nullable<number>
  }
  className?: string
  type: any
}

export const AcChartCircleFemales = ({
  seriesData,
  type,
  className,
}: IAcPieChartFemales) => {
  const viewportWidth = AcUseViewportWidth()
  const renderChart = useMemo(() => {
    const colorIndex = (type: string) => {
      switch (type) {
        case KEYS.DIRECTORS.toLowerCase():
          return 1
        case KEYS.SUBTOP.toLowerCase():
          return 3
        case KEYS.SUPERVISORY.toLowerCase():
          return 2
        case KEYS.ORGANISATION.toLowerCase():
          return 0
        default: {
          return 2
        }
      }
    }

    const color = DAEX_COLORS_SECONDARY_GRADIENT[colorIndex(type)]
    const textColor = COLORS.text.primary

    const { series } = AcPieChartFemalesMap({
      ...seriesData,
      textColor,
    })

    const opts = {
      animationDelayUpdate: function (idx: number) {
        return idx * 5
      },
      textStyle: {
        fontFamily: 'Dax',
      },
      color: [color, '#DADADA'],
      tooltip: {
        trigger: 'item',
        confine: true,
      },
      grid: {
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
      },
      series,
    }

    const getElementClasses = () => clsx(className && className)

    return (
      <ReactEChartsCore
        className={getElementClasses()}
        style={{ height: '164px', width: '100%' }}
        echarts={echarts}
        option={opts}
      />
    )
  }, [seriesData, type, viewportWidth])

  return renderChart
}
