import { DAEX_COLORS_SECONDARY, KEYS, LABELS } from '@constants'
import type {
  IAcCompanyDetailSeriesData,
  IAcCompanyDetailOverviewMarkline,
} from '@typings'

export interface IAcLineChartCompanyDetailOverviewMap {
  seriesData: IAcCompanyDetailSeriesData[]
  markLine?: IAcCompanyDetailOverviewMarkline
  showSymbol?: boolean
}

type IBarSeriesKey = 'ORGANISATION' | 'DIRECTORS' | 'SUPERVISORY' | 'SUBTOP'

const BAR_KEYS = [
  KEYS.ORGANISATION,
  KEYS.DIRECTORS,
  KEYS.SUPERVISORY,
  KEYS.SUBTOP,
]

export const AcLineChartCompanyDetailOverviewMap = ({
  seriesData,
  markLine,
  showSymbol = true,
}: IAcLineChartCompanyDetailOverviewMap) => {
  const LineChartConfig = {
    years: [] as string[],
    series: [] as any[],
  }

  // Loop over years in series
  for (const [index, series] of seriesData.entries()) {
    const { year, status, ...bars } = series
    const appendSeriesData = !!index

    LineChartConfig.years.push(year.toString())

    // Custom order of barKeys to match DAEX_COLORS_SECONDARY
    const visibleLineKeys: string[] = BAR_KEYS

    // Loop over bars inside one year (organisation, directors, etc.)
    for (const [lineIndex, _lineKey] of visibleLineKeys.entries()) {
      const lineKey = _lineKey as keyof typeof bars
      const bar = series[lineKey]
      const color = DAEX_COLORS_SECONDARY[lineIndex]

      const subsetMarkline = markLine?.[_lineKey as keyof typeof markLine]

      // Add percentage to data array if series is already initialized in first year
      if (appendSeriesData) {
        LineChartConfig.series[lineIndex].data.push(bar?.current.percentage)
      } else {
        LineChartConfig.series.push({
          name: LABELS[lineKey.toUpperCase() as IBarSeriesKey],
          type: 'line',
          data: [bar?.current.percentage],
          barWidth: 32,
          barGap: '150%',
          showSymbol,
          symbolSize: 8,
          markLine: {
            data: subsetMarkline?.target
              ? [
                  {
                    yAxis: subsetMarkline?.target,
                    name: `Streefcijfer ${subsetMarkline.year}`,
                    label: {
                      normal: {
                        show: true,
                        position: 'insideEndTop',
                        formatter: (item: any) => {
                          return `${item.value}%`
                        },
                      },
                    },
                  },
                ]
              : [],
            symbol: 'none',
          },
          lineStyle: {
            color,
            shadowBlur: 20,
            shadowColor: color,
            width: 3,
          },
          emphasis: {
            focus: 'series',
          },
          label: {
            position: 'bottom',
            formatter: ({ value }: any) => `${value}%`,
            offset: [0, 10],
            color: 'inherit',
            fontSize: 15,
            lineHeight: 20,
            align: 'center',
            rich: {
              bold: {
                fontSize: 15,
                align: 'center',
                fontWeight: 'bold',
                color,
              },
            },
          },
        })
      }
    }
  }

  return LineChartConfig
}
