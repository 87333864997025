import { BREAKPOINTS, ICONS, KEYS } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import { useStore } from '@hooks/ac-use-store'
import {
  IAnalyticsCompanyEmployeesItem,
  IAnalyticsEmployeesSubset,
  IAnalyticsOrganisationMetric,
} from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { AcDataItem } from '../ac-data-item/ac-data-item'
import { AcCardWithTitle } from '../index.features.components'
import { IAcCardBadge } from 'src/components/core/ac-card-badge/ac-card-badge'

interface IAcCompanyDetailPeopleProps {
  subset?: IAnalyticsEmployeesSubset
  title: string
  subTitle?: string
  badge?: IAcCardBadge
}

export const AcCompanyDetailEmployees = observer(
  ({
    subset = KEYS.ORGANISATION,
    title,
    subTitle,
    badge,
  }: IAcCompanyDetailPeopleProps) => {
    const { analytics } = useStore()
    const isDesktop = AcUseBreakpoint(BREAKPOINTS.SM)
    const currentColor = useMemo(() => {
      switch (subset) {
        case 'subtop':
          return '--ac-color-secondary-lightblue2'
        default:
          return '--ac-color-secondary-fuchia'
      }
    }, [subset])

    const getLabel = (type: IAnalyticsCompanyEmployeesItem['type']) => {
      switch (type) {
        case 'employees':
          return 'Aantal werknemers'
        case 'females':
          return 'Aantal vrouwen'
        case 'males':
          return 'Aantal mannen'
      }
    }

    const getIcon = (type: IAnalyticsCompanyEmployeesItem['type']) => {
      switch (type) {
        case 'employees':
          return ICONS.GROUP
        case 'males':
          return ICONS.MALES
        case 'females':
          return ICONS.FEMALES
      }
    }

    return (
      <AcCardWithTitle
        title={title}
        subTitle={subTitle}
        badge={badge}>
        {analytics.company.organisation_metrics &&
          analytics.company.organisation_metrics.map(
            (singleItem: IAnalyticsOrganisationMetric) => {
              const title = singleItem.value
              const renderedTitle =
                typeof title === 'number'
                  ? title.toLocaleString('nl-NL')
                  : title
              return (
                <AcDataItem
                  key={singleItem.type}
                  title={renderedTitle}
                  label={getLabel(singleItem.type)}
                  direction={isDesktop ? 'horizontal' : 'vertical'}
                  iconColor={currentColor}
                  icon={getIcon(singleItem.type)}
                />
              )
            }
          )}
      </AcCardWithTitle>
    )
  }
)
