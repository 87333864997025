import { KEYS, LABELS } from '@constants'
import {
  AcCompanyDetailAppointment,
  AcCompanyDetailDirectors,
  AcCompanyDetailWomenChartComponent,
  AcNoReporting,
  AcCompanyDetailElaboration,
  AcCompanyDetailAppointmentsGrid,
} from '@components'
import { useStore } from '@hooks/ac-use-store'

export interface IAcDataExplorerCompanyDirectorsTab {
  hasReported: boolean
  reasonMissing?: string
}

export const AcDataExplorerCompanyDirectorsTab = ({
  hasReported,
  reasonMissing,
}: IAcDataExplorerCompanyDirectorsTab) => {
  const { analytics } = useStore()

  if (!hasReported)
    return (
      <AcNoReporting
        type={`het ${LABELS.DIRECTORS.toLowerCase()}`}
        reasonMissing={reasonMissing}
      />
    )
  return (
    <>
      <AcCompanyDetailWomenChartComponent
        subset={KEYS.DIRECTORS}
        title="Percentage vrouwen in het bestuur"
        subTitle={`Percentage vrouwen in het bestuur`}
      />
      <AcCompanyDetailElaboration
        title="Toelichting streefcijfers"
        content={
          analytics.company.descriptions.director_targets_elaboration ||
          LABELS.NO_ELABORATION
        }
      />
      <AcCompanyDetailDirectors
        title="Man-vrouwverhouding in het bestuur"
        subTitle={`Aantal statutair bestuurders (natuurlijke personen)`}
        seats="Aantal bestuurders"
        subset={KEYS.DIRECTORS}
      />
      <AcCompanyDetailAppointmentsGrid subset="directors" />
      <AcCompanyDetailAppointment
        subset={KEYS.DIRECTORS}
        title="Benoemingen en herbenoemingen"
        subTitle={`Percentage vrouwen bij benoemingen, 
          herbenoemingen en aftredingen in het bestuur`}
      />
    </>
  )
}
