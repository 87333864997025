import { observer } from 'mobx-react-lite'
import { AcTypography } from '@components'
import { AcCardWithTitle } from '@components'

interface IAcCompanyDetailExplanationProps {
  title?: string
  content?: string
}

export const AcCompanyDetailElaboration = observer(
  ({ title, content }: IAcCompanyDetailExplanationProps) => {
    return (
      <AcCardWithTitle title={title}>
        <AcTypography weight="light">{content}</AcTypography>
      </AcCardWithTitle>
    )
  }
)
