import { KEYS, LABELS } from '@constants'
import {
  AcCompanyDetailEmployees,
  AcCompanyDetailSubtopFlow,
  AcCompanyDetailWomenChartComponent,
  AcNoReporting,
  AcCompanyDetailElaboration,
  AcCompanyDetailsSubtopFlowProgression,
} from '@components'
import { useStore } from '@hooks/ac-use-store'
import {
    AcCompanyDetailSubtopEmployees
} from "../../../../components/features/ac-company-detail-subtop-employees/ac-company-detail-subtop-employees";

export interface IAcDataExplorerCompanySubtopTab {
  hasReported: boolean
  reasonMissing?: string
}

export const AcDataExplorerCompanySubtopTab = ({
  hasReported,
  reasonMissing,
}: IAcDataExplorerCompanySubtopTab) => {
  const { analytics } = useStore()

  if (!hasReported)
    return (
      <AcNoReporting
        type={`de ${LABELS.SUBTOP.toLowerCase()}`}
        reasonMissing={reasonMissing}
      />
    )

  return (
    <>
      <AcCompanyDetailWomenChartComponent
        subset={KEYS.SUBTOP}
        title="Percentage vrouwen in de subtop"
        subTitle={`Percentage vrouwen in de subtop`}
      />
      <AcCompanyDetailElaboration
        title="Toelichting streefcijfers"
        content={
          analytics.company.descriptions.subtop_targets_elaboration ||
          LABELS.NO_ELABORATION
        }
      />
      <AcCompanyDetailElaboration
        title="Definitie subtop"
        content={
          analytics.company.descriptions.subtop_definition ||
          LABELS.NO_SUBTOP_DEFINITION
        }
      />
      <AcCompanyDetailSubtopEmployees
        title="Man-vrouwverhouding in de subtop"
        subTitle={`Aantal werknemers in de subtop (in personen)`}
        subset={KEYS.SUBTOP}
        badge={{
          label: `Einde boekjaar ${LABELS.CURRENT_YEAR}`,
          subset: 'subtop',
        }}
      />
      <AcCompanyDetailsSubtopFlowProgression />
      <AcCompanyDetailSubtopFlow
        title="In- en uitstroom subtop"
        subTitle={`Percentage vrouwen dat aangesteld werd 
          in de subtop (instroom) en dat de subtop verliet (uitstroom)`}
      />
    </>
  )
}
