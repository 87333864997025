import ReactEChartsCore from 'echarts-for-react/lib/core'
import { BarChart, GaugeChart, LineChart } from 'echarts/charts'
import {
  GridComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import * as echarts from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { useMemo } from 'react'

import { BREAKPOINTS, DAEX_COLORS_SECONDARY_GRADIENT, LABELS } from '@constants'
import {
  IAcCompanyDetailSubsetMarkline,
  IAcCompanyDetailSeriesData,
} from '@typings'
import { AcBarChartSubsetWomenCountMap } from './ac-bar-chart-subset-women-count-map'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import { renderTooltip } from './renderTooltip'
import styles from './ac-bar-chart-subset-women-count.module.scss'
import { xAxisYearLabelFormatter } from '../ac-charts-shared-styles/ac-charts-shared-styles'

// Register the required components
echarts.use([
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GaugeChart,
  BarChart,
  LineChart,
  MarkLineComponent,
  CanvasRenderer,
])

export interface IAcBarChartSubsetWomenCount {
  seriesData: IAcCompanyDetailSeriesData[]
  markLine: IAcCompanyDetailSubsetMarkline
  subset?: 'directors' | 'supervisory' | 'subtop'
  fullWidth?: boolean
}

export const AcBarChartSubsetWomenCount = ({
  seriesData,
  markLine,
  subset,
  fullWidth = false,
}: IAcBarChartSubsetWomenCount) => {
  const isMdAndUp = AcUseBreakpoint(BREAKPOINTS.MD)

  const renderChart = useMemo(() => {
    const charts: JSX.Element[] = []

    const addChart = (seriesData: IAcCompanyDetailSeriesData[]) => {
      const config = AcBarChartSubsetWomenCountMap({
        seriesData,
        markLine,
        subset,
        fullWidth,
      })

      // Add fake xAxis labels (years) to make the bars align left as per design
      if (isMdAndUp && subset && !fullWidth)
        config.years = [
          ...config.years,
          ...Array(12 - seriesData.length).fill(''),
        ]

      if (markLine?.target) {
        config.series.push({
          name: LABELS.TARGET,
          type: 'line',
          symbol: 'none',
          itemStyle: {
            color: 'transparent',
          },
          lineStyle: {
            color: 'black',
            type: 'dotted',
          },
        } as any)
      }

      const opts = {
        animationDelayUpdate: function (idx: number) {
          return idx * 5
        },
        tooltip: {
          trigger: 'axis',
          confine: true,
          formatter: (item: any) => renderTooltip(item, markLine),
        },
        textStyle: {
          fontFamily: 'Dax',
        },
        color: DAEX_COLORS_SECONDARY_GRADIENT,
        legend: {
          data: [LABELS.TARGET],
          formatter: LABELS.TARGET,
          selectedMode: false,
          right: 0,
        },
        grid: {
          bottom: 100,
          left: 40,
          right: 0,
        },
        xAxis: [
          {
            type: 'category',
            data: config.years,
            position: 'bottom',
            offset: config.isSubset ? 16 : 72,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: xAxisYearLabelFormatter(seriesData),
          },
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 100,
            interval: 10,
            axisLabel: {
              formatter: '{value}%',
            },
            minorTick: {
              splitNumber: 2,
            },
            minorSplitLine: {
              show: true,
            },
          },
        ],
        series: config.series,
      }

      charts.push(
        <ReactEChartsCore
          style={{ height: '420px' }}
          echarts={echarts}
          option={opts}
          key={config.years.join()}
        />
      )
    }

    if (isMdAndUp || subset) {
      // For desktop and subsets, render 1 chart
      addChart(seriesData)
    } else {
      // For tablet and below, render chart per year to be able to wrap
      for (const femalesYear of seriesData) {
        addChart([femalesYear])
      }
    }

    return charts
  }, [seriesData, markLine, subset, isMdAndUp])

  return (
    <div className={styles['ac-bar-chart-subset-women-count']}>
      {renderChart}
    </div>
  )
}
