import React, { useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { usePopper } from 'react-popper'
import { AcTypography } from '../index.core.components'
import styling from './ac-tooltip.module.scss'

export interface IAcTooltip {
  children: React.ReactNode
  label: string | string[]
  /** @default 400 */
  delay?: number
  visible?: boolean
}

export const AcTooltip = ({
  children,
  label,
  delay = 400,
  visible = true,
}: IAcTooltip) => {
  let timeout: ReturnType<typeof setTimeout>
  const [active, setActive] = useState<boolean>(false)
  const [refElement, setRefElement] = useState<HTMLSpanElement | null>(null)
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const [tooltipRef, setTooltipRef] = useState<HTMLDivElement | null>(null)
  const { styles, attributes } = usePopper(refElement, tooltipRef, {
    placement: 'auto',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 16],
        },
      },
    ],
  })

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay)
  }

  const hideTip = () => {
    clearInterval(timeout)
    setActive(false)
  }

  if (!visible) return <>{children}</>

  const renderLabel = useMemo(() => {
    if (Array.isArray(label)) {
      return (
        <AcTypography weight="regular">
          {label.map(text => (
            <div key={text}>{text}</div>
          ))}
        </AcTypography>
      )
    }
    return <AcTypography weight="regular">{label}</AcTypography>
  }, [label])

  return (
    <div
      id="tooltip"
      ref={setRefElement}
      onMouseEnter={showTip}
      onMouseLeave={hideTip}>
      {children}
      {active && (
        <>
          {createPortal(
            <div
              style={styles.popper}
              ref={setTooltipRef}
              className={styling['ac-tooltip']}
              {...attributes.popper}>
              <div
                ref={setArrowElement}
                style={styles.arrow}
                className={styling['ac-tooltip-arrow']}
              />
              {renderLabel}
            </div>,
            document.querySelector('#root') as Element
          )}
        </>
      )}
    </div>
  )
}
