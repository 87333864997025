import { DAEX_COLORS_SECONDARY, KEYS, LABELS } from '@constants'
import { IAnalyticsIndexFemales } from '@typings'
import { toJS } from 'mobx'

export interface IAcLineChartFemaleProgressionMap {
  seriesData: IAnalyticsIndexFemales
  showSymbol?: boolean
}

type IBarSeriesKey = 'ORGANISATION' | 'DIRECTORS' | 'SUPERVISORY' | 'SUBTOP'

const BAR_KEYS = [
  KEYS.ORGANISATION,
  KEYS.DIRECTORS,
  KEYS.SUPERVISORY,
  KEYS.SUBTOP,
]

export const AcLineChartFemaleProgessionMap = ({
  seriesData,
  showSymbol = true,
}: IAcLineChartFemaleProgressionMap) => {
  const AcChartLineFemalesConfig = {
    years: [] as string[],
    series: [] as any[],
  }

  // Loop over years in series
  for (const [index, series] of seriesData.entries()) {
    const { year, ...bars } = series
    const appendSeriesData = !!index

    AcChartLineFemalesConfig.years.push(year.toString())

    // Custom order of barKeys to match DAEX_COLORS_SECONDARY
    const visibleBarKeys: string[] = BAR_KEYS

    // Loop over bars inside one year (organisation, directors, etc.)
    for (const [barIndex, _barKey] of visibleBarKeys.entries()) {
      const barKey = _barKey as keyof typeof bars
      const bar = series[barKey]
      const color = DAEX_COLORS_SECONDARY[barIndex]

      // Add percentage to data array if series is already initialized in first year
      if (appendSeriesData) {
        AcChartLineFemalesConfig.series[barIndex].data.push(bar.percentage)
      } else {
        AcChartLineFemalesConfig.series.push({
          name: LABELS[barKey.toUpperCase() as IBarSeriesKey],
          type: 'line',
          data: [bar.percentage],
          barWidth: 32,
          barGap: '150%',
          showSymbol,
          symbolSize: 8,
          lineStyle: {
            color,
            shadowBlur: 20,
            shadowColor: color,
            width: 3,
          },
          emphasis: {
            focus: 'series',
          },
          label: {
            position: 'bottom',
            formatter: ({ value }: any) => `${value}%`,
            offset: [0, 10],
            color: 'inherit',
            fontSize: 15,
            lineHeight: 20,
            align: 'center',
            rich: {
              bold: {
                fontSize: 15,
                align: 'center',
                fontWeight: 'bold',
                color,
              },
            },
          },
        })
      }
    }
  }

  return AcChartLineFemalesConfig
}
