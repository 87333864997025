import { AxiosBaseApi } from '@config/axios.config'
import { ENDPOINTS } from '@constants'
import {
  IAnalyticsCompany,
  IAnalyticsCompanyDirectors,
  IAnalyticsCompanyEmployees,
  IAnalyticsCompanyFemalesYear,
  IAnalyticsCompanyPlan,
  IAnalyticsCompanySubtopFlow,
  IAnalyticsDefinition,
  IAnalyticsIndexAppointments,
  IAnalyticsIndexAverage,
  IAnalyticsIndexFemales,
  IAnalyticsIndexFilters,
  IAnalyticsIndexFlow,
  IAnalyticsIndexMaidenless,
  IAnalyticsIndexTarget,
  IAnalyticsIndexPva,
  IAnalyticsSearchResponse,
  IAppointmentData,
} from '@typings'
import { AxiosRequestConfig, AxiosResponse } from 'axios'

export class AnalyticsApi extends AxiosBaseApi {
  /* DEFAULT CRUD */
  get_single_by_slug = (
    slug: IAnalyticsCompany['slug'],
    config?: AxiosRequestConfig
  ) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsCompany }>>(
        ENDPOINTS.ANALYTICS.COMPANY_SLUG(slug),
        config
      )
      .then(response => response.data.data)

  get_females = (
    slug: IAnalyticsCompany['slug'],
    config?: AxiosRequestConfig
  ) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsCompanyFemalesYear[] }>>(
        ENDPOINTS.ANALYTICS.FEMALES(slug),
        config
      )
      .then(response => response.data.data)

  get_companies = (
    slug: IAnalyticsCompany['slug'],
    config?: AxiosRequestConfig
  ) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsCompany[] }>>(
        ENDPOINTS.ANALYTICS.COMPANIES(slug),
        config
      )
      .then(response => response.data.data)

  get_index_filters = (config?: AxiosRequestConfig) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsIndexFilters }>>(
        ENDPOINTS.ANALYTICS.INDEX_FILTERS(),
        config
      )
      .then(response => response.data.data)
  get_index_females = (config?: AxiosRequestConfig) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsIndexFemales }>>(
        ENDPOINTS.ANALYTICS.INDEX_FEMALES(),
        config
      )
      .then(response => response.data.data)
  get_index_average = (config?: AxiosRequestConfig) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsIndexAverage }>>(
        ENDPOINTS.ANALYTICS.INDEX_AVERAGE(),
        config
      )
      .then(response => response.data.data)
  get_index_target = (config?: AxiosRequestConfig) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsIndexTarget }>>(
        ENDPOINTS.ANALYTICS.INDEX_TARGET(),
        config
      )
      .then(response => response.data.data)
  get_index_appointments = (config?: AxiosRequestConfig) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsIndexAppointments }>>(
        ENDPOINTS.ANALYTICS.INDEX_APPOINTMENTS(),
        config
      )
      .then(response => response.data.data)
  get_index_flow = (config?: AxiosRequestConfig) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsIndexFlow }>>(
        ENDPOINTS.ANALYTICS.INDEX_FLOW(),
        config
      )
      .then(response => response.data.data)
  get_index_maidenless = (config?: AxiosRequestConfig) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsIndexMaidenless }>>(
        ENDPOINTS.ANALYTICS.INDEX_MAIDENLESS(),
        config
      )
      .then(response => response.data.data)
  get_index_pva = (config?: AxiosRequestConfig) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsIndexPva }>>(
        ENDPOINTS.ANALYTICS.INDEX_PVA(),
        config
      )
      .then(response => response.data.data)
  get_detail_plan = (
    slug: IAnalyticsCompany['slug'],
    config?: AxiosRequestConfig
  ) =>
    this.api
      .get<AxiosResponse<{ data: IAnalyticsCompanyPlan }>>(
        ENDPOINTS.ANALYTICS.DETAIL_PLAN(slug),
        config
      )
      .then(response => response.data.data)

  get_search_results = (params: any, config?: AxiosRequestConfig) =>
    this.api
      .get<AxiosResponse<IAnalyticsSearchResponse>>(
        ENDPOINTS.ANALYTICS.SEARCH(),
        { params, ...config }
      )
      .then(response => response.data)

  /* ACTIONS */
  /* JOINS */
}
