export const LABELS = {
  HERO_TITLE: 'Genderbalans in het Nederlandse bedrijfsleven',
  HERO_TEXT: `Jaarlijks rapporteren grote vennootschappen over de man-vrouwverhouding in hun bedrijfstop en subtop. Deze rapportageverplichting komt voort uit de Wet ingroeiquotum en streefcijfers. De SER Dataverkenner maakt de informatie en ontwikkeling van de Genderbalans in het Nederlandse bedrijfsleven zichtbaar. `,
  CORPERATE_TITLE_APPENDIX: ' | Sociaal-Economische Raad',
  TOTAL_WOMAN_TOP_SUBTOP: '',
  APPOINTMENT: 'benoemingen',
  APPOINTMENT_ALT: 'Benoemingen',
  LOADING: 'Laden...',
  REAPPOINTMENT: 'herbenoemingen',
  REAPPOINTMENT_ALT: 'Herbenoemingen',
  ORGANISATION: 'Organisatie',
  DIRECTORS: 'Bestuur',
  SUPERVISORY: 'RvC',
  RESIGN: 'aftredingen',
  RESIGN_ALT: 'Aftredingen',
  RESULTS: 'resultaten',
  RESULT: 'resultaat',
  SUBTOP: 'Subtop',
  NEXT: 'Volgende',
  PREVIOUS: 'Vorige',
  TARGET: 'Streefcijfer',
  TARGET_YEAR: 'Streefjaar',
  TARGET_EXPLANATION:
    'Een streefcijfer van 30% betekent: ten minste 30% mannen en ten minste 30% vrouwen.',
  PLAN: 'Plan van aanpak',
  SECTOR: 'Bedrijfstak',
  CLEAR_ALL: 'Alles wissen',
  SEARCH_COMPANIES_BY_NAME_OR_KVK: 'Zoek bedrijven op naam of KVK-nummer',
  SIZE_CLASS: 'Grootteklasse',
  STOCK_EXCHANGE_LISTING: 'Beursgenoteerd in NL',
  MINIMAL_2_CHARACTERS: 'Voer minimaal 2 karakters in',
  YES: 'Ja',
  NO: 'Nee',
  NO_DATA_AVAILABLE: 'Geen data beschikbaar',
  EMPLOYEES: 'medewerkers',
  YOU_WERE_SEARCHING_FOR: 'Je zocht naar',
  ALL_OPTIONS: 'Alle entiteiten',
  AVERAGE_PERCENTAGE_WOMAN: 'Gemiddeld percentage vrouwen',
  AVERAGE_TARGET: 'gemiddeld streefcijfer',
  CURRENT_YEAR: '2023',
  BACK: 'Terug',
  BACK_TO_DATA_EXPLORER: 'Terug naar dataverkenner',
  INFLUX: 'Ingestroomd',
  OUTFLOW: 'Uitgestroomd',
  NOT_APPLICABLE: '-',
  REPORT_NOT_AVAILABLE: 'Dit bedrijf heeft niet gerapporteerd over ',
  REPORT_EXTENDED_YEAR:
    'Dit bedrijf heeft een gebroken boekjaar en rapporteert op een later moment.',
  HAS_PVA: 'Formeel plan van aanpak aanwezig',
  HAS_SHARED_PVA: 'Waarvan: gedeeld met de organisatie en/of stakeholders',
  BOOKYEAR_END_DATE: 'Datum einde boekjaar',
  NO_ELABORATION: 'Geen toelichting gegeven',
  NO_SUBTOP_DEFINITION: 'Geen subtop definitie beschikbaar',
} as const

export const DYNAMIC_LABELS = {
  N_COMPANIES: (n: number) => (n === 1 ? '1 bedrijf' : `${n} bedrijven`),
}
