import { useMemo } from 'react'
import { AcCard } from '@components'
import { useStore } from '@hooks/ac-use-store'
import DidNotReportVisual from '@assets/visuals/did-not-report.png'
import styles from './ac-no-reporting.module.scss'

interface IAcNoReporting {
  type: string
  reasonMissing?: string
}

export const AcNoReporting = ({ type, reasonMissing }: IAcNoReporting) => {
  const { analytics } = useStore()

  if (!analytics?.company) return null

  const { is_part_of_consolidated_declaration, name } = analytics.company
  const companyName = name || 'dit bedrijf'

  const didNotReportReason = useMemo(() => {
    switch (type) {
      case 'het bestuur':
        return `${
          name || 'Dit bedrijf'
          // eslint-disable-next-line max-len
        } rapporteert niet over het bestuur of uitvoerende bestuurders omdat het bestuur uitsluitend uit rechtspersoon-bestuurders bestaat.`

      case 'de RvC':
        return `${
          name || 'Dit bedrijf'
          // eslint-disable-next-line max-len
        } rapporteert niet over RvC of niet-uitvoerende bestuurders omdat ${companyName} geen RvC of niet-uitvoerende bestuurders heeft.`

      case 'de subtop':
        return `${
          name || 'Dit bedrijf'
          // eslint-disable-next-line max-len
        } rapporteert niet over de subtop omdat ${companyName} geen subtop of medewerkers in leidinggevende functies heeft.`

      default:
        return ''
    }
  }, [type])

  return (
    <AcCard>
      <div
        className={styles['ac-no-reporting']}
        style={{ textAlign: 'center' }}>
        <img
          src={DidNotReportVisual}
          alt="Geen rapportage beschikbaar"
          width={162}
          height={172}
        />
          <p>{didNotReportReason}</p>
      </div>
    </AcCard>
  )
}
