import { IAcBarChartSubsetWomenCount } from '@charts'

export const renderTooltip = (
  args: any,
  markLine: IAcBarChartSubsetWomenCount['markLine']
) => {
  if (!markLine.target && markLine?.target !== 0) {
    return 'nog (geen) streefcijfer vastgesteld'
  }

  const tooltip = `
    <div data-name="bar-chart-women-count-tooltip">
      <span><strong>${args[0].value}% vrouwen in ${args[0].axisValue}</strong></span> 
      <br />
      <span>Streefcijfer: ${markLine.target}% vrouwen in ${markLine.year}</span>
    </div>
  `

  return tooltip
}
