import { useCallback } from 'react'
import { AcCardWithTitle } from '@components'
import { DAEX_ICON_MAP } from '@constants'
import styles from './ac-company-detail-appointments-grid.module.scss'
import { useMemo } from 'react'
import { useStore } from '@hooks/ac-use-store'
import { IAnalyticsAppointments } from '@typings'
import { AcBarChartSubsetAppointments } from '@charts'

export interface IAcCompanyDetailAppointmentsGrid {
  subset: 'directors' | 'supervisory'
}

export const AcCompanyDetailAppointmentsGrid = ({
  subset,
}: IAcCompanyDetailAppointmentsGrid) => {
  const { analytics } = useStore()
  const iconColor = useMemo(() => {
    switch (subset) {
      case 'directors':
        return 'var(--ac-color-secondary-orange)'

      case 'supervisory':
        return 'var(--ac-color-secondary-green)'
    }
  }, [subset])

  const appointmentsData = useMemo(() => {
    return analytics.sortedLongitudinalYear.map(
      ({ year, appointments, status }) => ({
        year,
        status,
        data: appointments?.[subset],
      })
    )
  }, [analytics.sortedLongitudinalYear.length])

  const findDataType = useCallback(
    (type: IAnalyticsAppointments['type']) => {
      return appointmentsData.map(year => ({
        year: year.year,
        status: year.status,
        data: year.data?.find(item => item.type === type) || null,
      }))
    },
    [appointmentsData]
  )

  const renderAppointmentsChart = useMemo(() => {
    if (!appointmentsData?.length) return null
    const data = findDataType('appointment')
    if (!data?.length) return null
    return (
      <AcBarChartSubsetAppointments
        subset={subset}
        data={data}
      />
    )
  }, [appointmentsData])

  const renderReappointmentChart = useMemo(() => {
    if (!appointmentsData?.length) return null
    const data = findDataType('reappointment')
    if (!data?.length) return null
    return (
      <AcBarChartSubsetAppointments
        subset={subset}
        data={data}
      />
    )
  }, [appointmentsData])

  const renderResignationChart = useMemo(() => {
    if (!appointmentsData?.length) return null
    const data = findDataType('resign')
    if (!data?.length) return null
    return (
      <AcBarChartSubsetAppointments
        subset={subset}
        data={data}
      />
    )
  }, [appointmentsData])

  return (
    <>
      <AcCardWithTitle
        icon={DAEX_ICON_MAP.appointment}
        iconSize={32}
        iconColor={iconColor}
        title={subset == 'directors' ? 'Benoemingen in het bestuur' : 'Benoemingen in de RvC'}
        subTitle="Percentage vrouwen bij benoemingen">
        {renderAppointmentsChart}
      </AcCardWithTitle>
      <div className={styles['ac-company-details-appointments-grid']}>
        <AcCardWithTitle
          icon={DAEX_ICON_MAP.reappointment}
          iconSize={32}
          iconColor={iconColor}
          title={subset == 'directors' ? 'Herbenoemingen in het bestuur' : 'Herbenoemingen in de RvC'}
          subTitle="Percentage vrouwen bij herbenoemingen">
          {renderReappointmentChart}
        </AcCardWithTitle>
        <AcCardWithTitle
          icon={DAEX_ICON_MAP.resign}
          iconSize={32}
          iconColor={iconColor}
          title={subset == 'directors' ? 'Aftredingen in het bestuur' : 'Aftredingen in de RvC'}
          subTitle="Percentage vrouwen bij aftredingen">
          {renderResignationChart}
        </AcCardWithTitle>
      </div>
    </>
  )
}
