import { BREAKPOINTS } from '@constants'
import { AcUseBreakpoint } from '@hooks/ac-use-breakpoint'
import { useStore } from '@hooks/ac-use-store'
import { AcUseVisible } from '@hooks/ac-use-visible'
import {
  IAnalyticsLongitudinalYear,
  IAcCompanyDetailOverviewMarkline,
  IAcCompanyDetailSubsetMarkline,
} from '@typings'
import { observer } from 'mobx-react-lite'
import { useMemo, useRef } from 'react'
import {
  AcCard,
  AcDivider,
  AcTitle,
  AcTypography,
} from '../../core/index.core.components'
import {
  AcBarChartSubsetWomenCount,
  AcLineChartCompanyDetailOverview,
  IAcBarChartSubsetWomenCountMap,
} from '@charts'

export interface IAcCompanyDetailWomenChartComponenProps {
  subset?: IAcBarChartSubsetWomenCountMap['subset']
  title?: string
  subTitle?: string
}

const findCount = (
  input: IAnalyticsLongitudinalYear['gender_balance_governing_bodies'],
  key: string
) => {
  if (!input) return null
  return input[key as keyof typeof input].find(({ type }) => type === 'females')
}

const createSeriesData = (
  item: IAnalyticsLongitudinalYear,
  subset?: 'directors' | 'supervisory' | 'subtop'
) => {
  const { year, gender_balance_governing_bodies, targets, status } = item
  const keys = ['directors', 'subtop', 'supervisory']
  const data: any = {
    year,
    status,
  }
  if (!subset) {
    keys.push('organisation')

    keys.forEach(key => {
      data[key] = {
        current: {
          value: findCount(gender_balance_governing_bodies, key)?.value,
          percentage: findCount(gender_balance_governing_bodies, key)
            ?.percentage,
        },
        target: {
          percentage: targets?.[key as keyof typeof targets]?.target,
          year: targets?.[key as keyof typeof targets]?.year,
        },
      }
    })
  } else {
    data[subset] = {
      current: {
        value: findCount(gender_balance_governing_bodies, subset)?.value,
        percentage: findCount(gender_balance_governing_bodies, subset)
          ?.percentage,
      },
      target: {
        percentage: targets?.[subset as keyof typeof targets]?.target,
        year: targets?.[subset as keyof typeof targets]?.year,
      },
    }
  }
  return data
}

const createMarkLine = (
  item: IAnalyticsLongitudinalYear,
  subset?: 'directors' | 'supervisory' | 'subtop'
): unknown => {
  if (subset) {
    return item.targets?.[subset as keyof typeof item.targets]
  } else {
    return {
      directors: item?.targets?.directors,
      supervisory: item?.targets?.supervisory,
      subtop: item?.targets?.subtop,
    }
  }
}

export const AcCompanyDetailWomenChartComponent = observer(
  ({ subset, title, subTitle }: IAcCompanyDetailWomenChartComponenProps) => {
    const { analytics } = useStore()
    const isMdAndUp = AcUseBreakpoint(BREAKPOINTS.SM)
    const containerRef = useRef(null)
    const isVisible = AcUseVisible(containerRef.current)
    const isEntireOrganisationChart = !subset

    const renderSubsetBarChart = useMemo(() => {
      // if (!isVisible) return null

      const charts: JSX.Element[] = []

      const seriesData = analytics.sortedLongitudinalYear.map(item =>
        createSeriesData(item, subset)
      )

      const markLine = createMarkLine(
        analytics.sortedLongitudinalYear[
          analytics.sortedLongitudinalYear.length - 1
        ],
        subset
      )

      if (isMdAndUp || subset) {
        // For desktop and subsets, render 1 chart
        charts.push(
          <AcBarChartSubsetWomenCount
            seriesData={seriesData}
            markLine={markLine as IAcCompanyDetailSubsetMarkline}
            subset={subset}
            fullWidth
          />
        )
      } else {
        // For tablet and below, render chart per year to be able to wrap
        for (const data of [seriesData]) {
          charts.push(
            <AcBarChartSubsetWomenCount
              seriesData={data}
              markLine={markLine as IAcCompanyDetailSubsetMarkline}
              subset={subset}
              fullWidth
            />
          )
        }
      }

      return charts
    }, [isVisible, isMdAndUp, analytics.sortedLongitudinalYear, subset])

    const renderMultiYearLineChart = useMemo(() => {
      if (!analytics?.sortedLongitudinalYear?.length) return null
      const seriesData = analytics.sortedLongitudinalYear.map(item =>
        createSeriesData(item)
      )

      const markLine = createMarkLine(
        analytics.sortedLongitudinalYear[
          analytics.sortedLongitudinalYear.length - 1
        ]
      )

      return (
        <AcLineChartCompanyDetailOverview
          seriesData={seriesData}
          markLine={markLine as IAcCompanyDetailOverviewMarkline}
        />
      )
    }, [isVisible, isMdAndUp, analytics.sortedLongitudinalYear])

    return (
      <AcCard>
        <AcTitle level={3}>{title}</AcTitle>
        <AcTypography weight="light">{subTitle}</AcTypography>
        <AcDivider />
        {isEntireOrganisationChart
          ? renderMultiYearLineChart
          : renderSubsetBarChart}
      </AcCard>
    )
  }
)
