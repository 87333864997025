import { KEYS, LABELS } from '@constants'
import {
  AcCompanyDetailAppointment,
  AcCompanyDetailElaboration,
  AcCompanyDetailWomenChartComponent,
  AcNoReporting,
  AcCompanyDetailAppointmentsGrid,
} from '@components'
import { useStore } from '@hooks/ac-use-store'
import {
    AcCompanyDetailSupervisory
} from "../../../../components/features/ac-company-detail-supervisory/ac-company-detail-supervisory";

export interface IAcDataExplorerCompanySupervisoryTab {
  hasReported: boolean
  reasonMissing?: string
}

export const AcDataExplorerCompanySupervisoryTab = ({
  hasReported,
  reasonMissing,
}: IAcDataExplorerCompanySupervisoryTab) => {
  const { analytics } = useStore()
  if (!hasReported)
    return (
      <AcNoReporting
        type={`de ${LABELS.SUPERVISORY}`}
        reasonMissing={reasonMissing}
      />
    )

  return (
    <>
      <AcCompanyDetailWomenChartComponent
        subset={KEYS.SUPERVISORY}
        title="Percentage vrouwen in de RvC"
        subTitle={`Percentage vrouwen in de RvC`}
      />
      <AcCompanyDetailElaboration
        title="Toelichting streefcijfers"
        content={
          analytics.company.descriptions.director_targets_elaboration ||
          LABELS.NO_ELABORATION
        }
      />
      <AcCompanyDetailSupervisory
        title="Aantal commissarissen"
        subTitle={`Aantal commissarissen / niet-uitvoerende bestuurders`}
        subset={KEYS.SUPERVISORY}
        seats="Aantal zetels"
      />
      <AcCompanyDetailAppointmentsGrid subset="supervisory" />

      <AcCompanyDetailAppointment
        subset={KEYS.SUPERVISORY}
        title="Benoemingen en herbenoemingen"
        subTitle={`Percentage vrouwen bij benoemingen, 
          herbenoemingen en aftredingen in de RvC`}
      />
    </>
  )
}
