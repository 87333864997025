import { CSSProperties, useMemo } from 'react'

import { ICONS, KEYS, LABELS } from '@constants'
import { IAnalyticsCompanyDepartments } from '@typings'

import { AcCardWithTitle, AcIcon, AcTitle, AcTooltip } from '@components'
import { AcChartCircleFemales } from '../../charts/ac-pie-chart-females/ac-pie-chart-females'

import styles from './ac-card-circle-females.module.scss'

interface IAcCardCircleFemales {
  title: string
  subtitle: string
  seriesData: {
    value: Nullable<number>
    percentage: Nullable<number>
    type: string
  }[]
}

export const AcCardCircleFemales = ({
  title,
  subtitle,
  seriesData,
}: IAcCardCircleFemales) => {
  const renderCharts = useMemo(() => {
    return seriesData.map((chart, i) => {
      const department =
        LABELS[chart.type?.toUpperCase() as IAnalyticsCompanyDepartments]

      const colorVar = (type: string) => {
        switch (type) {
          case KEYS.DIRECTORS.toLowerCase():
            return '--ac-color-secondary-orange'
          case KEYS.SUBTOP.toLowerCase():
            return '--ac-color-secondary-lightblue2'
          case KEYS.SUPERVISORY.toLowerCase():
            return '--ac-color-secondary-green'
          case KEYS.ORGANISATION.toLowerCase():
            return '--ac-color-secondary-fuchia'
          default: {
            return '--ac-color-secondary-green'
          }
        }
      }

      const getToolTip = (department: string) => {
        switch (department) {
          case LABELS.ORGANISATION:
            return 'Bedrijven met één of meer werknemers'
          case LABELS.DIRECTORS:
            return 'Bedrijven met een bestuur bestaande uit natuurlijke personen'

          case LABELS.SUBTOP:
            return 'Bedrijven met één of meer werknemers in de subtop'

          case LABELS.SUPERVISORY:
            return 'Bedrijven met een RvC /niet- uitvoerende bestuurders'

          default:
            return ''
        }
      }

      return (
        <div
          className={styles['ac-card-circle-females-chart']}
          key={i}
          style={
            { '--color': `var(${colorVar(chart.type)})` } as CSSProperties
          }>
          <AcTitle
            level={4}
            className={styles['ac-card-circle-females-chart-title']}
            style={{ color: 'var(--color)' }}>
            {department}

            <AcTooltip label={getToolTip(department)}>
              <AcIcon
                className={styles['ac-card-circle-females-chart-title-tooltip']}
                icon={ICONS.INFO}
                size={14}
              />
            </AcTooltip>
          </AcTitle>
          <div
            className={
              styles['ac-card-circle-females-chart-component-wrapper']
            }>
            <AcChartCircleFemales
              className={styles['ac-card-circle-females-chart-component']}
              seriesData={chart}
              type={chart.type}
            />
          </div>
        </div>
      )
    })
  }, [seriesData])

  return (
    <AcCardWithTitle
      title={title}
      subTitle={subtitle}>
      {renderCharts}
    </AcCardWithTitle>
  )
}
