import { useMemo } from 'react'
import styles from './ac-card-badge.module.scss'

export interface IAcCardBadge {
  label: string
  color?: string
  subset?: string
}

export const AcCardBadge = ({ label, color, subset }: IAcCardBadge) => {
  const renderColor = useMemo(() => {
    if (!subset) return color
    switch (subset) {
      case 'organisation':
        return '#F6BCEC'

      case 'directors':
        return '#FBD79E'

      case 'supervisory':
        return 'rgba(217, 219, 162)'

      case 'subtop':
        return '#D3E7F4'
    }
  }, [color, subset])

  return (
    <div
      className={styles['ac-card-badge']}
      style={{ '--ac-card-badge-color': renderColor } as React.CSSProperties}>
      {label}
    </div>
  )
}
