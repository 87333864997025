import { Children, useMemo } from 'react'
import {
  AcCard,
  AcCardBadge,
  AcDivider,
  AcIcon,
  AcTitle,
  AcTypography,
} from '../../core/index.core.components'
import { ICONS, LABELS } from '@constants'

import styles from './ac-card-with-title.module.scss'
import { IAcCardBadge } from 'src/components/core/ac-card-badge/ac-card-badge'

interface IAcCardWithTitleProps {
  icon?: keyof typeof ICONS
  title?: string
  subTitle?: string
  children?: React.ReactNode
  badge?: IAcCardBadge
  iconSize?: number
  iconColor?: string
}

export const AcCardWithTitle = ({
  icon,
  title,
  subTitle,
  children,
  badge,
  iconSize = 72,
  iconColor = 'var(--ac-color-primary)',
}: IAcCardWithTitleProps) => {
  const childLength = useMemo(() => {
    const length = Children.count(children)

    return length > 4 ? 4 : length
  }, [children])

  return (
    <AcCard className={styles['ac-card-with-title']}>
      <div className={styles['ac-card-with-title-header']}>
        <div
          className={styles['ac-card-with-title-header__title']}
          style={
            {
              '--ac-card-with-title-icon-color': iconColor,
            } as React.CSSProperties
          }>
          {icon && (
            <AcIcon
              size={iconSize}
              icon={icon}
              className={styles['ac-card-with-title-icon']}
            />
          )}
          {(title || subTitle) && (
            <>
              <div className={styles['ac-card-with-title-title-wrapper']}>
                <AcTitle level={3}>{title}</AcTitle>
                {subTitle && (
                  <AcTypography
                    className={styles['ac-card-with-title-title-sub']}
                    element="span"
                    weight="light">
                    {subTitle}
                  </AcTypography>
                )}
              </div>
            </>
          )}
        </div>
        {badge && <AcCardBadge {...badge} />}
      </div>
      <AcDivider />
      <div
        className={styles['ac-card-with-title-content']}
        data-grid-count={childLength}>
        {children}
      </div>
    </AcCard>
  )
}
