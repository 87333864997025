import { IAcCompanyDetailSeriesData } from '@typings'
import { XAXisComponentOption } from 'echarts'
import { IAcBarChartSubsetAppointmentsYear } from '@charts'
import ErrorIcon from '@assets/icons/cross.svg'
import InfoIcon from '@assets/icons/info.svg'

type xAxisYearLabelFormatterData =
  | IAcCompanyDetailSeriesData
  | IAcBarChartSubsetAppointmentsYear

export const xAxisYearLabelFormatter = (
  seriesData: xAxisYearLabelFormatterData[]
): XAXisComponentOption['axisLabel'] => ({
  formatter: (value: string) => {
    const yearStatus = seriesData?.find(
      ({ year }) => year?.toString() === value
    )?.status

    if (yearStatus === 'not-available') {
      return `{label| ${value}}\n{error|}{pad|}{sub|Dit bedrijf heeft niet \n gerapporteerd over ${value}}`
    }
    if (yearStatus === 'not-required') {
      return `{label| ${value}}\n{info|}{pad|}{sub|Dit bedrijf hoefde niet \n te rapporteren in ${value}}`
    }
    return value
  },
  interval: 0,
  color: '#000',
  fontSize: 14,
  fontWeight: 600,
  rich: {
    label: {
      color: '#000',
      fontSize: 14,
      fontWeight: 600,
      padding: 4,
    },
    error: {
      backgroundColor: {
        image: ErrorIcon,
      },
    },
    info: {
      backgroundColor: {
        image: InfoIcon,
      },
    },
    pad: {
      padding: 4,
    },
    sub: {
      color: '#241D6D',
      fontSize: 10,
      fontWeight: 400,
    },
  },
})
